import React from 'react';
import { Separator} from 'office-ui-fabric-react';
import { Checkbox,ChoiceGroup,IChoiceGroupOption} from 'office-ui-fabric-react';
import { Text,TextField } from 'office-ui-fabric-react';
import { FontSizes } from 'office-ui-fabric-react';
import { Stack,IStackItemStyles, MessageBar, MessageBarType,  } from 'office-ui-fabric-react';
import FamilyMemberModel from '../../models/FamilyMemberModel';

import { actionConfirmationChange, actionFamMemberChange } from '../../actions/familyConfirmationPanelActions';


interface ParticipantEntryProps {
    participant:FamilyMemberModel
}
interface ParticipantEntryState {
    saved?:boolean
}

const stackItemStyles: IStackItemStyles = {
    root: {
      padding: 5
    }
};

export class ParticipantEntry extends React.Component<ParticipantEntryProps,ParticipantEntryState> {
    public render() {
        let confirmations = this.props.participant.confirmations;
        let invitations = this.props.participant.invitementslots;
        let foodpref = this.props.participant.foodpref;
        var debounce = require('debounce');

        return (
                
                <Stack styles={{root:{paddingLeft:'10px'}}} >
                    <Text styles={{root:{paddingBottom:'20px',paddingTop:'5px'}}}>
                        <b>{this.props.participant.name}</b> komt graag samen met jullie de liefde vieren op:
                    </Text>
                    <Stack wrap disableShrink tokens={{childrenGap: 5}}>
                        {
                            invitations.townhall?
                                (
                                    <Stack.Item align="start" styles={stackItemStyles} >
                                        <Checkbox label="Vrijdag 26 juni - 15u" checked={confirmations.townhall} onChange={() => {
                                            actionConfirmationChange(
                                                this.props.participant.id,
                                                {
                                                    ...this.props.participant.confirmations, 
                                                    townhall:!this.props.participant.confirmations.townhall,
                                                    not:false
                                                })}}></Checkbox>
                                        <Stack  styles={{root:{paddingLeft:'30px',paddingTop:'5px'}}} >
                                            <Text styles={{root:{fontSize:FontSizes.small}}}>
                                                Het officiële jawoord gevolgd door een glaasje.<br/>
                                                Locatie: Gemeentehuis Schilde
                                            </Text>
                                        </Stack>
                                    </Stack.Item>
                                ):("")
                        }
                        {
                            invitations.ceremony?
                            (
                                <Stack.Item align="start" styles={stackItemStyles}>
                                    <Checkbox label="Zaterdag 27 juni - 13u-16u" checked={confirmations.ceremony} onChange={() => {
                                        actionConfirmationChange(
                                            this.props.participant.id,
                                            {
                                                ...this.props.participant.confirmations, 
                                                ceremony:!this.props.participant.confirmations.ceremony,
                                                not:false
                                            })}}></Checkbox>
                                    <Stack  styles={{root:{paddingLeft:'30px',paddingTop:'5px'}}} >
                                        <Text styles={{root:{fontSize:FontSizes.small}}}>
                                            Romantisch jawoord met aansluitend een hapje en een drankje.<br/>
                                            Locatie: Lijsterbeslaan 3, Schilde
                                        </Text>
                                    </Stack>
                                </Stack.Item>
                            ):("")
                        }
                        {
                            invitations.diner?
                            (
                        
                                <Stack.Item align="start" styles={stackItemStyles}>
                                    <Checkbox label="Zaterdag 27 juni - 17u30-19u30" checked={confirmations.diner} onChange={() => {
                                        actionConfirmationChange(
                                            this.props.participant.id,
                                            {
                                                ...this.props.participant.confirmations, 
                                                diner:!this.props.participant.confirmations.diner,
                                                not:false
                                            })}}></Checkbox>
                                    <Stack  styles={{root:{paddingLeft:'30px',paddingTop:'5px'}}} >
                                        <Text styles={{root:{fontSize:FontSizes.small}}}>
                                            Voetjes onder de tafel en even bijpraten tijdens het etentje in de tuin.<br/>
                                            Locatie: Lijsterbeslaan 3, Schilde
                                        </Text>
                                    </Stack>
                                </Stack.Item>
                            ):("")
                        }
                        {
                            invitations.party?
                            (
                                <Stack.Item align="start" styles={stackItemStyles}>
                                    <Checkbox label="Zaterdag 27 juni - 20u-2u" checked={confirmations.party} onChange={() => {
                                        actionConfirmationChange(
                                            this.props.participant.id,
                                            {
                                                ...this.props.participant.confirmations, 
                                                party:!this.props.participant.confirmations.party,
                                                not:false
                                            })}}></Checkbox>
                                    <Stack  styles={{root:{paddingLeft:'30px',paddingTop:'5px'}}} >
                                        <Text styles={{root:{fontSize:FontSizes.small}}}>
                                            Een zoete start van de avond, gevolgd door een fijn feestje in de tuin.<br/>
                                            Locatie: Lijsterbeslaan 3, Schilde
                                        </Text>
                                    </Stack>
                                </Stack.Item>
                            ):("")
                        }
                        <Stack.Item align="start" styles={stackItemStyles}>
                            <Checkbox label="Ik kan er helaas niet bij zijn" checked={confirmations.not} onChange={() => {
                                actionConfirmationChange(
                                    this.props.participant.id,
                                    {
                                        ...this.props.participant.confirmations, 
                                        not:!this.props.participant.confirmations.not,
                                        townhall:false,
                                        ceremony:false,
                                        diner:false,
                                        party: false
                                    })}}></Checkbox>
                        </Stack.Item>
                    </Stack>
                    {
                                            
                        confirmations.not || 
                        (
                            confirmations.ceremony || 
                            confirmations.diner || 
                            confirmations.party ||
                            confirmations.townhall)? "":(<MessageBar messageBarType={MessageBarType.severeWarning} isMultiline={false}>Selecteer wanneer u er bij wenst te zijn of geef aan dat u er niet bij kan zijn.</MessageBar>)
                    } 
                    
                    {
                        
                            confirmations.diner?(
                                <div>
                                    <Separator styles={{root:{paddingTop:'30px'}}}></Separator>
                                    <Text>
                                        Vertel ons jouw voedingsvoorkeuren of allergieën?
                                    </Text>
                                    <Stack horizontalAlign="center">
                                        <ChoiceGroup
                                            styles={{root:{paddingTop:'30px'}}}
                                            id='foodprefs'
                                            selectedKey={this.props.participant.foodpref}
                                            required={true}
                                            options={[
                                                {
                                                key: 'Anything',
                                                iconProps: { iconName: 'FangBody' },
                                                text: 'Ik eet alles',
                                                defaultChecked:true
                                                },
                                                {
                                                key: 'Veggie',
                                                iconProps: { iconName: 'Cotton' },
                                                text: 'Veggie aub'
                                                },
                                                {
                                                key: 'Special',
                                                iconProps: { iconName: 'AutoEnhanceOn' },
                                                text: 'Heb je even?'
                                                }
                                            ]}

                                            onChange={
                                                (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
                                                    actionFamMemberChange(
                                                        {
                                                            ...this.props.participant, 
                                                            foodpref: option===undefined?"":option.key
                                                        })
                                                    }
                                                }
                                            />
                                            
                                    </Stack>
                                    <TextField placeholder="Geef meer details over je voorkeuren/allergieën indien nodig" styles={{root:{paddingTop:'30px'}}} onChange={debounce(
                                        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>,newValue?:string) => {
                                                    
                                            actionFamMemberChange(
                                                {
                                                    ...this.props.participant, 
                                                    foodprefnotes: newValue===undefined?"":newValue
                                                })
                                            }
                                            ,800)} value={this.props.participant.foodprefnotes}/>
                                
                                    
                                </div>
                            ):("")
                        
                    }
                    {
                        confirmations.diner && foodpref==="" ? (<MessageBar messageBarType={MessageBarType.severeWarning} isMultiline={false}>Duid aub uw voedingsvoorkeuren aan en geef eventuele allergieën door. </MessageBar>):""
                    }
                        <Separator styles={{root:{paddingTop:'20px'}}}></Separator>
                        <Text>
                            Heb je een vraag, boodschap of is je naam verkeerd gespeld?
                        </Text>
                        <TextField placeholder="Laat het ons weten..." styles={{root:{paddingTop:'20px'}}} onChange={debounce(
                                (ev?: React.FormEvent<HTMLElement | HTMLInputElement>,newValue?:string) => {
                                    
                                    actionFamMemberChange(
                                        {
                                            ...this.props.participant, 
                                            information: newValue===undefined?"":newValue
                                        })
                                    }
                                ,800)} value={this.props.participant.information}/>
                        
                </Stack>
        );
    }
};
