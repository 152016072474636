import { createStore } from 'redux';
import FamilyModel from '../models/FamilyModel';
import { FamilyConfirmationPanelState } from '../components/FamilyConfirmationPanel/FamilyConfirmationPanel';
import { FamilyConfirmationPanelActions } from '../actions/familyConfirmationPanelActions';

var defaultState = {
    loadState: 'INIT',
    saveState: 'SAVED',
    family: {} as FamilyModel
}

export function familyConfirmationPanelReducer(state:FamilyConfirmationPanelState = defaultState ,action:FamilyConfirmationPanelActions) {
    //Log the current state for debugging purposes
    console.log("Reducer [in] state:");
    console.log(state);

    let newState = state;

    //TODO: write reducers
    if(action.type === 'FAM_FETCH') {
        newState =  {
            // Make the store state immutable
            ...state,
            family: {} as FamilyModel,
            loadState: 'LOADING',
            saveState: 'NA'
        }
    }
    if(action.type === 'FAM_FETCH_SUCCESS') {
        newState = {
            ...state,
            loadState: 'LOADED',
            saveState: 'SAVED',
            family: action.family
        }

    }
    if(action.type === 'FAM_FETCH_ERROR') {
        newState = {
            ...state,
            loadState: 'ERROR',
            saveState: 'NA',
            family: {} as FamilyModel,
            errorMessage: action.errorMessage
        }
    }
    if(action.type === 'FAM_MEMBER_STORE') {
        newState = {
            ...state,
            saveState: 'SAVING'
        }
    }
    if(action.type === 'FAM_MEMBER_STORE_SUCCESS') {
        newState = {
            ...state,
            saveState: 'SAVED',
            family: {...state.family/*,
                members: Object.assign([],{
                    ...state.family.members.map(member => {
                            //member.confirmed = true;
                            return member;
                    })                    
                })*/
            }
        }
    }
    if(action.type === 'FAM_MEMBER_STORE_ERROR') {
        newState = {
            ...state,
            saveState: 'ERROR',
            errorMessage: action.errorMessage
        }

    }
    if(action.type === 'FAM_MEMBER_SELECTED') {
        newState = {
            ...state,
            family: {...state.family,
                members: Object.assign([],{
                    ...state.family.members.map(member => {
                            member.selected = member.id === action.familymemberid ? true:false;
                            return member;
                    })                    
                })
            }            
        }
    }

    if(action.type === 'CONFIRMATION_CHANGE') {
        newState = {
            ...state,
            saveState: 'UNSAVED',
            family: {
                ...state.family,
                members: Object.assign([],{
                    ...state.family.members.map(member => {
                        member.confirmations = member.id === action.familymemberid ? action.confirmations:member.confirmations;
                        member.confirmed = member.id === action.familymemberid? false:member.confirmed;
                        return member;
                    }) 
                })
            }               
        }
    }

    if(action.type === 'FAM_MEMBER_CHANGE') {
        newState = {
            ...state,
            saveState: 'UNSAVED',
            family: {
                ...state.family,
                members: Object.assign([],{
                    ...state.family.members.map(member => {
                        member = member.id === action.familymember.id ? action.familymember:member;
                        return member;
                    }) 
                })
            }               
        }
    }
    console.log("Reducer [out] state:");
    console.log(newState);

    return newState;
}

const store = createStore(familyConfirmationPanelReducer, defaultState);

export default store;
