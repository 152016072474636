import * as React from 'react';
import { DefaultButton, IIconProps } from 'office-ui-fabric-react';
import { actionFamMemberSelected } from '../../actions/familyConfirmationPanelActions';

export interface IParticipantToggleButtonProps {
  // These are set based on the toggles shown above the examples (not needed in real code)
  disabled?: boolean;
  on?: boolean;
  filled?: boolean;
  label:string;
  memberid:number;
}

const ParticipantIcon: IIconProps = { iconName: 'Save'};
const ParticipantFilledIcon: IIconProps = { iconName: 'CheckMark'};

export const ParticipantToggleButton: React.FunctionComponent<IParticipantToggleButtonProps> = props => {
  const { disabled, on, filled, label, memberid } = props;
  
  const onClick = () => { actionFamMemberSelected(memberid); }

  return (
    <DefaultButton
      toggle
      checked= {on}
      text={label}
      iconProps={filled ? ParticipantFilledIcon : ParticipantIcon}
      primary= {on}
      onClick={onClick}
      allowDisabledFocus
      disabled={disabled}
    />
  );
};
