import * as React from 'react';
import { ActionButton, DefaultPalette, DefaultButton, Text } from 'office-ui-fabric-react';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { useConstCallback } from '@uifabric/react-hooks';
import {Separator,Icon} from 'office-ui-fabric-react';
import {Stack} from 'office-ui-fabric-react';
import ReactHtmlParser from 'react-html-parser';

// The panel type and description are passed in by the PanelSizesExample component (later in this file)
export const InfoPanel: React.FunctionComponent<{ title:string,introtext:string, manualtext:string,toggletext:string }> = props => {
  const { title,introtext, manualtext,toggletext } = props;
  const [isOpen, setIsOpen] = React.useState(true);

  const openPanel = useConstCallback(() => setIsOpen(true));
  const closePanel = useConstCallback(() => setIsOpen(false));
  const dismissPanel = useConstCallback(() => setIsOpen(false));

  return (
    <div className="ms-Grid" dir="ltr">
      <Stack styles={{root:{background:DefaultPalette.neutralLight,marginBottom:"10px"}}}>
        <ActionButton iconProps={{ iconName: 'Info' }} allowDisabledFocus onClick={openPanel}>
          {toggletext}
        </ActionButton>
      </Stack>
      <Panel
        isOpen={isOpen}
        onDismiss={dismissPanel}
        type={PanelType.smallFluid}
        closeButtonAriaLabel="Close"
        headerText={title}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm4"></div>
          <div className="ms-Grid-col ms-sm4">
            <Separator>
              <Icon iconName="Heart" styles={{root: {fontSize: "25px", height: "25px", width: "25px"}}}/>
              <Icon iconName="Heart" styles={{root: {fontSize: "25px", height: "25px", width: "25px", color:DefaultPalette.red}}}/>
              <Icon iconName="Heart" styles={{root: {fontSize: "25px", height: "25px", width: "25px"}}}/>
            </Separator>
          </div>
          <div className="ms-Grid-col ms-sm4"></div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm4"></div>
          <div className="ms-Grid-col ms-sm4"  >
            <Stack horizontalAlign="start">
              {ReactHtmlParser(introtext)}
              <br/><br/><DefaultButton iconProps={{ iconName: 'Play' }} text="Beginnen!" onClick={closePanel}/>
            </Stack>
          </div>
          <div className="ms-Grid-col ms-sm4"></div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm4"></div>
          <div className="ms-Grid-col ms-sm4">
            <Separator/>
          </div>
          <div className="ms-Grid-col ms-sm4"></div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm4">
          </div>
          <div className="ms-Grid-col ms-sm4">
            <Text variant='xLarge' nowrap block>Hoe starten?</Text>
            <br/>
            <Stack horizontalAlign="start">
              {ReactHtmlParser(manualtext)}
            </Stack>
          </div>
          <div className="ms-Grid-col ms-sm4"></div>
        </div>
      </Panel>
    </div>
  );
};

