import FamilyMemberModel from '../models/FamilyMemberModel'
import FamilyModel from '../models/FamilyModel'
import axios from 'axios'

export default class FamilyService {
    static getById(id:string): Promise<FamilyModel> {
        return new Promise((resolve, reject)=> {
            console.log("Getting family by id");
            axios({
                method:'post',
                url:'https://prod-24.westeurope.logic.azure.com:443/workflows/34b1eab989564fe29b6905e240a531d9/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=KtKPVJzbRkbQNsXba81lXjgOGyBJYK7WEKvHCsckJ5w',
                data: {
                    familyid:id
                }
            }).then((response) => {
                console.log("Response:");
                console.log(response.data);
                resolve(response.data);
            }, (err) => {
                reject(err);
            })
        });
    }

    static updateFamilyMember(familyid:number,familymember:FamilyMemberModel): Promise<FamilyMemberModel> {
        return new Promise((resolve, reject)=> {
            console.log("Updating family" + familyid);
            console.log(familymember);
            axios({
                method:'post',
                url:'https://prod-85.westeurope.logic.azure.com:443/workflows/bdb733c2657c49d99fd6e1d3d5b10d41/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=gPU41nhfubhjIdj8-DPvygls9RlmnnO_XHQiVMzqWBM',
                data: {
                    familymember:familymember,
                    familyid:familyid
                }
            }).then((response) => {
                console.log("Response:");
                console.log(response.data);
                resolve(response.data);
            }, (err) => {
                reject(err);
            })
        });
    }
}
