import React from 'react';
import { NavBar } from '../../components/NavBar/NavBar';
import { ParticipantEntry } from '../../components/ParticipantEntry/ParticipantEntry';
import { Stack, IStackItemStyles, Separator, Icon, DefaultPalette, DefaultButton, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { ParticipantToggleButton } from '../../components/ToggleButton/ToggleButton';
import { InfoPanel } from '../../components/InfoPanel/InfoPanel';
import { initializeIcons, Label } from 'office-ui-fabric-react';
import queryString from 'query-string';

import FamilyModel from '../../models/FamilyModel';

import { connect } from 'react-redux';
import { actionFetchFamily, actionFamMemberChange,actionFamMemberSelected, actionFamMemberStore } from '../../actions/familyConfirmationPanelActions';
import FamilyMemberModel from '../../models/FamilyMemberModel';



initializeIcons();
// Styling of the button stack
const participantButtonStackStyles: IStackItemStyles = {
    root: {
        padding: 5
    }
};

interface FamilyConfirmationPanelProps {
    loadState: string, //'INIT' | 'LOADING' | 'LOADED' | 'ERROR'
    saveState: string, // 'NA' | 'UNSAVED' | 'SAVING' | 'SAVED' | 'ERROR'
    family: FamilyModel,
    errorMessage?: string
}


export interface FamilyConfirmationPanelState {
    loadState: string, //'INIT' | 'LOADING' | 'LOADED' | 'ERROR'
    saveState: string, // 'NA' | 'UNSAVED' | 'SAVING' | 'SAVED' | 'ERROR'
    family: FamilyModel,
    errorMessage?: string
}

const stackItemStyles: IStackItemStyles = {
    root: {
      padding: 5
    }
};


export class FamilyConfirmationPanel extends React.Component<FamilyConfirmationPanelProps,FamilyConfirmationPanelState> {
    componentDidMount() {
        if(this.props.loadState === 'INIT') {
            console.log('initializing familyConfirmationPanel component');

            // TODO: load family by id from request param
            const qs =queryString.parse(window.location.search);
            const familyid = String(qs.familyid)
            actionFetchFamily(familyid);
        }
    }
    public render() {
        let toggleButtonSection;
        let participantEntrySection;
        switch(this.props.loadState){
            case 'INIT': {
                toggleButtonSection = <MessageBar messageBarType = {MessageBarType.info}>Initializing App...</MessageBar>;
                participantEntrySection = <MessageBar messageBarType = {MessageBarType.info}>Initializing App</MessageBar>;
                break;
            }
            case 'LOADING': {
                toggleButtonSection = <MessageBar messageBarType = {MessageBarType.info}>Loading your family members...</MessageBar>;
                participantEntrySection = <MessageBar messageBarType = {MessageBarType.info}>Loading your family members...</MessageBar>;
                break;
            }
            case 'LOADED': {
                let currentMembers = this.props.family.members.sort((a,b) => (a.id<b.id) ? -1 : 1);

                toggleButtonSection = 
                    <Stack>
                        <Stack horizontalAlign="center">
                            <span>
                                <Label>Klik op een familielid om de deelname door te geven:<br/></Label>
                            </span>
                        </Stack>
                        <Stack horizontal wrap disableShrink tokens={{ childrenGap: 5}} horizontalAlign="center">
                            
                            {
                                currentMembers.map( (member,i) => {
                                    return (
                                        <Stack.Item align="auto" styles={participantButtonStackStyles} key={i}>
                                            <ParticipantToggleButton label={member.name} filled={member.confirmed} on={member.selected} memberid={member.id}/>
                                        </Stack.Item>
                                    )
                                })
                            }   
                        </Stack>
                    </Stack>;

                    // Show the information pane for the selected members
                    if (currentMembers.find(member => member.selected === true) != null ) 
                        currentMembers.forEach( (member,i) => {
                            if(member.selected) {
                                participantEntrySection =  (
                                    <Stack>
                                        <ParticipantEntry participant={member}/>
                                        <Separator styles={{root:{paddingTop:'20px'}}}></Separator>
                                        
                                        
                                        
                                        
                                        <Stack horizontal wrap disableShrink tokens={{childrenGap: 5}} horizontalAlign="center">
                                        <Stack.Item align="start" styles={stackItemStyles}>
                                                <DefaultButton text="Bevestig" iconProps={{ iconName: 'Save' }} onClick={() => { 
                                                    let familymember: FamilyMemberModel = {
                                                        ...member, 
                                                        confirmed: true
                                                    };
                                                    actionFamMemberChange(familymember);
                                                    actionFamMemberStore(this.props.family.id,familymember); 
                                                }} disabled={this.props.saveState==='UNSAVED'?false:true}></DefaultButton>
                                            </Stack.Item>
                                            <Stack.Item align="start" styles={stackItemStyles}>
                                                <DefaultButton text="Vorige" iconProps={{ iconName: 'ChevronLeftMed' }} disabled={member.id===1  || this.props.saveState !== 'SAVED'} onClick={() => { actionFamMemberSelected(member.id-1); }}></DefaultButton>
                                            </Stack.Item>
                                            <Stack.Item align="start" styles={stackItemStyles}>
                                                <DefaultButton text="Volgende" iconProps={{ iconName: 'ChevronRightMed' }} disabled={member.id===currentMembers.length || this.props.saveState !== 'SAVED'} onClick={() => { actionFamMemberSelected(member.id+1); }}></DefaultButton>
                                            </Stack.Item>
                                            
                                        </Stack>
                                    </Stack>
                                );
                            }
                        });
                    
                    // no family member selected yet
                    else {
                        actionFamMemberSelected(1);
                        //participantEntrySection = (<MessageBar messageBarType = {MessageBarType.info}>Selecteer een familielid om je deelname te bevestigen/annuleren. De personen waarvoor een opgevuld hartje staat werden reeds doorgegeven, maar kan u indien gewenst nog aanpassen.</MessageBar>) 
                    }
                break;
            }
            case 'ERROR': {
                toggleButtonSection = <MessageBar messageBarType = {MessageBarType.info}>An error occured</MessageBar>;
                break;
            }
            default: {
                toggleButtonSection=<MessageBar messageBarType = {MessageBarType.info}>App state unknown</MessageBar>
            }
        }

        return (
            // make the app responsive using the Office UI fabric grid structure
            <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-lg8">
                <NavBar/>          
            </div>
            </div>
            <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
                <InfoPanel
                introtext='
                    Op een bijzondere dag als onze trouwdag mogen jullie natuurlijk niet ontbreken. Weet je of je er wel of niet bij kan zijn? Deel het dan hier.<br/><br/>
                    Dichter bij de datum laten we opnieuw van ons horen en kan je dit nog aanpassen.<br/><br/>
                    Nataly &amp; Ben'
                manualtext='
                    Bovenaan in de app vind je knoppen met de namen van alle genodigden. Klik je een knop aan, dan verschijnt het deelnameformulier. Met de knop &quot;Bevestig&quot; helemaal onderaan het formulier stuurt u de gegevens door. <br/><br/>Indien er meerdere familieleden uitgenodigd zijn, klikt op de knop &quot;volgende&quot; om ook voor hen de deelname door te geven. Vergeet niet telkens te bevestigen. 
                    <br/><br/>Als de registratie niet lukt, mag je ons ook altijd een mailtje sturen via nataly.guns@gmail.com of ben.vanmol@co-lab.eu.<br/>
                    Tot binnenkort, wij kijken er alvast naar uit!
                '
                title='Nataly &amp; Ben trouwen!'
                toggletext='Weet je niet hoe te starten? Lees het hier...'/>
            </div>
            </div>
            <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
               {toggleButtonSection}
            </div>
            <div className="ms-Grid-col ms-sm4 ms-lg2">
                <Stack horizontalAlign="center">
                    <Stack styles={{root:{padding:'10px'}}}>
                        <Separator>
                            <Icon iconName="Heart" styles={{root: {fontSize: '25px', height: '25px', width: '25px'}}}/>
                            <Icon iconName="Heart" styles={{root: {fontSize: '25px', height: '25px', width: '25px', color:DefaultPalette.red}}}/>
                            <Icon iconName="Heart" styles={{root: {fontSize: '25px', height: '25px', width: '25px'}}}/>
                            
                        </Separator>
                        {participantEntrySection}
                    </Stack>
                </Stack>  
            </div>
            </div>
        </div>
        );
    }
};

const mapStateToProps = (store: FamilyConfirmationPanelState, ownProps: FamilyConfirmationPanelProps) => {
    return {
        loadState: store.loadState,
        saveState: store.saveState,
        family: store.family,
        errorMessage: store.errorMessage
    }
}

// const mapDispatchToProps = (dispatch:any) => { //tslint:disable-line
//     return {
//         loadData: () => dispatch(actionFetchFamily('1'))
//     };
// };

export default connect(mapStateToProps)(FamilyConfirmationPanel);