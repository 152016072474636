import React from 'react';
import { Text,Stack, IStackStyles, FontSizes, DefaultPalette } from '../../../node_modules/office-ui-fabric-react';

const navBarStyles: IStackStyles = {
  root: {
    background: DefaultPalette.red,
    padding:'10px'
  }
};
const textStyles: IStackStyles = {
  root: {
    color: DefaultPalette.white,
    fontSize: FontSizes.xLarge
  }
};

export const NavBar: React.FunctionComponent = () => {
  return (
    <Stack styles={navBarStyles} horizontalAlign="center" verticalAlign="center">
      <Text styles={textStyles} nowrap> Nataly &amp; Ben trouwen</Text>
      

    </Stack>
    
  );
};


