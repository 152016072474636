import React from 'react';
import ReactDOM from 'react-dom';
import { FamilyConfirmationPanel } from './components/FamilyConfirmationPanel/FamilyConfirmationPanel';
import { mergeStyles, loadTheme } from 'office-ui-fabric-react';
import * as serviceWorker from './serviceWorker';
import store from './store/configureStore';

// Inject some global styles
mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#root)': {
      margin: 0,
      padding: 0,
      height: '100vh'
    }
  }
});
//Define the theme for the app
loadTheme({
  palette: {
    themePrimary: '#d44300',
    themeLighterAlt: '#fdf6f3',
    themeLighter: '#f8ddd0',
    themeLight: '#f2c0a9',
    themeTertiary: '#e5875c',
    themeSecondary: '#d9561a',
    themeDarkAlt: '#be3c00',
    themeDark: '#a13300',
    themeDarker: '#772600',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#bab8b7',
    neutralSecondary: '#a3a2a0',
    neutralPrimaryAlt: '#8d8b8a',
    neutralPrimary: '#323130',
    neutralDark: '#605e5d',
    black: '#494847',
    white: '#ffffff',
  }
});



class MainComponent extends React.Component {
  componentDidMount() {
    store.subscribe(() => {
      this.setState({});
    });
  }
  render() {
    return (
      <FamilyConfirmationPanel 
        saveState={store.getState().saveState}
        loadState = {store.getState().loadState} 
        family={store.getState().family}
        />
    );
  }
}

ReactDOM.render(<MainComponent/>,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
