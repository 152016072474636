import FamilyModel from '../models/FamilyModel';
import FamilyMemberModel from '../models/FamilyMemberModel';
import { Action } from 'redux';
import FamilyService from '../services/family';
import store from '../store/configureStore';
import PartySlotsModel from '../models/PartySlotsModel';


export function isAction<A extends Action>(action: Action, type: string): action is A {
  return action.type === type;
}

/**
 * Fetch Family
 */
// Fetch Family constants
export const ACTION_FAM_FETCH = 'FAM_FETCH';
export const ACTION_FAM_FETCH_SUCCESS = 'FAM_FETCH_SUCCESS';
export const ACTION_FAM_FETCH_ERROR = 'FAM_FETCH_ERROR';

// Interfaces
export interface IActionFamFetch extends Action {
  type: 'FAM_FETCH'
  id:string
}

export interface IActionFamFetchSuccess extends Action {
  type: 'FAM_FETCH_SUCCESS',
  family: FamilyModel
}

export interface IActionFamFetchError extends Action {
  type: 'FAM_FETCH_ERROR',
  errorMessage: string
}

// Action & Dispatch methods
function dispatchFetchFamProgress(id:string): IActionFamFetch {
  console.log("Action: " + ACTION_FAM_FETCH);
  return {
    type: ACTION_FAM_FETCH,
    id
  };
}

function dispatchFetchFamSuccess(family: FamilyModel): IActionFamFetchSuccess {
  console.log("Action: " + ACTION_FAM_FETCH_SUCCESS);
  return {
    type: ACTION_FAM_FETCH_SUCCESS,
    family
  };
}

function dispatchFetchFamError(e: Error): IActionFamFetchError {
  console.log("Action: " + ACTION_FAM_FETCH_ERROR);
  return {
    type: ACTION_FAM_FETCH_ERROR,
    errorMessage: e.message
  };
}

export async function actionFetchFamily(id:string) {

  store.dispatch(dispatchFetchFamProgress(id));

  // Get family data from service
  try {
    const family = await FamilyService.getById(id);
    return store.dispatch(dispatchFetchFamSuccess(family));
  }
  catch (e) {
    return store.dispatch(dispatchFetchFamError(e));
  }
}

/**
 * Confirmation Change
 */

 // Confirmation change constants
export const ACTION_CONFIRMATION_CHANGE = 'CONFIRMATION_CHANGE';

// Interfaces
export interface IActionConfirmationChange extends Action {
  type: 'CONFIRMATION_CHANGE',
  familymemberid:number,
  confirmations: PartySlotsModel
}

//Actions & dispatch functions
function dispatchConfirmationChange(familymemberid:number,confirmations:PartySlotsModel): IActionConfirmationChange {
  console.log("Action: " + ACTION_CONFIRMATION_CHANGE);
  return {
    type: ACTION_CONFIRMATION_CHANGE,
    familymemberid,
    confirmations
  };
}

export function actionConfirmationChange(familymemberid:number, confirmations:PartySlotsModel) {
  store.dispatch(dispatchConfirmationChange(familymemberid, confirmations));
}

/**
 * Family Member Change
 */

 // Confirmation change constants
 export const ACTION_FAM_MEMBER_CHANGE = 'FAM_MEMBER_CHANGE';

 // Interfaces
 export interface IActionFamMemberChange extends Action {
   type: 'FAM_MEMBER_CHANGE'
   familymember:FamilyMemberModel
 }
 
 //Actions & dispatch functions
 function dispatchFamMemberChange(familymember:FamilyMemberModel): IActionFamMemberChange {
   console.log("Action: " + ACTION_FAM_MEMBER_CHANGE);
   console.log(familymember);
   return {
     type: ACTION_FAM_MEMBER_CHANGE,
     familymember
   };
 }
 
 export function actionFamMemberChange(familymember:FamilyMemberModel) {
   store.dispatch(dispatchFamMemberChange(familymember));
 }

// Update Family constants
export const ACTION_FAM_MEMBER_STORE = 'FAM_MEMBER_STORE';
export const ACTION_FAM_MEMBER_STORE_SUCCESS = 'FAM_MEMBER_STORE_SUCCESS';
export const ACTION_FAM_MEMBER_STORE_ERROR = 'FAM_MEMBER_STORE_ERROR';

// Interfaces
export interface IActionFamMemberStore extends Action {
  type: 'FAM_MEMBER_STORE',
  familyid: number,
  familymember: FamilyMemberModel
}

export interface IActionFamMemberStoreSuccess extends Action {
  type: 'FAM_MEMBER_STORE_SUCCESS',
  familyid:number,
  familymember: FamilyMemberModel
}

export interface IActionFamMemberStoreError extends Action {
  type: 'FAM_MEMBER_STORE_ERROR',
  errorMessage: string
}

// Actions & dispatch functions
function dispatchFamMemberStoreProgress(familyid: number, familymember:FamilyMemberModel): IActionFamMemberStore {
  console.log("Action: " + ACTION_FAM_MEMBER_STORE);
  return {
    type: ACTION_FAM_MEMBER_STORE,
    familyid,
    familymember
  };
}

function dispatchFamMemberStoreSuccess(familyid:number, familymember: FamilyMemberModel): IActionFamMemberStoreSuccess {
  console.log("Action: " + ACTION_FAM_MEMBER_STORE_SUCCESS);
  return {
    type: ACTION_FAM_MEMBER_STORE_SUCCESS,
    familyid,
    familymember
  };
}

function dispatchFamMemberStoreError(e: Error): IActionFamMemberStoreError {
  console.log("Action: " + ACTION_FAM_MEMBER_STORE_ERROR);
  return {
    type: ACTION_FAM_MEMBER_STORE_ERROR,
    errorMessage: e.message
  };
}

export function actionFamMemberStore(familyid:number,familymember:FamilyMemberModel) {
  store.dispatch(dispatchFamMemberStoreProgress(familyid,familymember));
  // Update family data through service
  return FamilyService.updateFamilyMember(familyid, familymember)
  .then((familymember:FamilyMemberModel) => {
    return store.dispatch(dispatchFamMemberStoreSuccess(familyid,familymember));
  })
  .catch((e: Error) => {
    return store.dispatch(dispatchFamMemberStoreError(e));
  });
}

/**
 * UI Interactions
 */
// Family Member Selected constant
export const ACTION_FAM_MEMBER_SELECTED = 'FAM_MEMBER_SELECTED';

// Interfaces
export interface IActionFamMemberSelected extends Action {
  type: 'FAM_MEMBER_SELECTED',
  familymemberid: number
}

// Actions & dispatch functions
function dispatchFamMemberSelected(familymemberid: number): IActionFamMemberSelected {
  console.log("Action: FAM_MEMBER_SELECTED");
  return {
    type: ACTION_FAM_MEMBER_SELECTED,
    familymemberid
  };
}

export function actionFamMemberSelected(familymemberid: number) {
  return store.dispatch(dispatchFamMemberSelected(familymemberid));
  
}


export type FamilyConfirmationPanelActions = IActionFamFetch | IActionFamFetchSuccess | IActionFamFetchError | IActionFamMemberStore | IActionFamMemberStoreSuccess | IActionFamMemberStoreError | IActionFamMemberSelected | IActionConfirmationChange | IActionFamMemberChange;



